import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Login,
  Register,
  // Admin,
  RegisterdDevice,
  UserManager,
  DeviceManager,
  DeviceToken,
  QRCode,
  FirmwareManager,
  SystemLogs,
} from "./components";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/Register" component={Register} />
          {/* <Route exact path="/Admin" component={Admin} /> */}
          <Route exact path="/RegisterdDevice" component={RegisterdDevice} />
          <Route exact path="/usermanager" component={UserManager} />
          <Route exact path="/devicemanager" component={DeviceManager} />
          <Route exact path="/devicetoken" component={DeviceToken} />
          <Route exact path="/qrcode" component={QRCode} />
          <Route exact path="/firmware_manager" component={FirmwareManager} />
          <Route exact path="/systemlogs" component={SystemLogs} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
