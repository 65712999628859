import React, { useState, useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import { TextField } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import CachedIcon from "@material-ui/icons/Cached";
import config from "./config.json";
// import { AESencrypt, AESdecrypt } from "./AESEncoder";
import { createCookie, deleteCookie, accessCookie } from "./CookieManagement";

export default function Login(props) {
  const history = useHistory();

  let [state, setState] = useState({
    username: "",
    password: "",
    captcha: "",
    captchatoken: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      deleteCookie("admintok");
    } else {
      history.push("/usermanager");
    }
  }

  async function LoginSession() {
    let response = await fetch(config.backend_url + "/be/admin/login", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      // body: AESencrypt( JSON.stringify(state), config.aes_key),
      body: JSON.stringify(state),
    });

    let data = await response.json();
    if (response.status === 200) {
      createCookie("admintok", data.access_token, 0.5);
      history.push("./usermanager");
    } else {
      //getCaptcha();
      if (document.getElementById("warning")) {
        document.getElementById("warning").innerHTML = "Please Try Again";
      }
    }
  }
  async function getCaptcha() {
    let response = await fetch(config.backend_url + "/getcaptcha", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });
    let data = await response.json();
    if (response.status === 200) {
      setState((prevstate) => ({
        ...prevstate,
        captchatoken: data.captcha,
      }));
      if (document.getElementById("captcha")) {
        document.getElementById("captcha").innerHTML =
          '<img src="' +
          config.backend_url +
          "/captchaimg/" +
          data.captcha +
          '" width="80%">';
      }
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    }
    getCaptcha();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div>
      {/* <Navbar logout={false} /> */}
      <div className="container home-block">
        <div className="home-card">
          <div className="text-title">Login to BSE Admin</div>
          <div className="textfield">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <PersonIcon />
              <TextField
                label="Username"
                className="textfield"
                style={{ marginLeft: "8px" }}
                name="username"
                value={state.username}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="textfield">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <VpnKeyIcon />
              <TextField
                label="Password"
                type="password"
                className="textfield"
                style={{ marginLeft: "8px" }}
                name="password"
                value={state.password}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="textfield">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
                id="captcha"
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <CachedIcon onClick={getCaptcha} />
                  <TextField
                    label="Captcha"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="captcha"
                    value={state.captcha}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-text">
            <div
              className="text-option"
              id="warning"
              style={{ color: "red" }}
            ></div>
          </div>
          <div
            className="button"
            style={{ cursor: "pointer" }}
            onClick={LoginSession}
          >
            <div className="button-text-white">Login</div>
          </div>

          <div
            className="button-border"
            style={{ cursor: "pointer" }}
            onClick={() => history.push("/Register")}
          >
            <div className="button-text">Register</div>
          </div>
        </div>
      </div>
    </div>
  );
}
