import "./Header.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { deleteCookie } from "../CookieManagement";
import {
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
export default function Navbar(props) {
  let [state, setState] = useState({ menustate: false });

  const history = useHistory();
  return (
    <nav style={{ paddingTop: "30px", width: "90%", maxWidth: "800px" }}>
      <div className="mynav">
        <IconButton
          style={{ marginLeft: "4px" }}
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              menustate: true,
            }));
          }}
        >
          <MenuIcon />
        </IconButton>
        <div className="text-primary-color">{props.title}</div>
      </div>
      <Drawer
        anchor="left"
        open={state.menustate}
        onClose={() => {
          setState((prevState) => ({ ...prevState, menustate: false }));
        }}
      >
        <div className="drawer-background">
          <List style={{ width: "200px" }} className="drawer">
            <ListItem
              button
              key={"User Manager"}
              onClick={() => {
                history.push("/usermanager");
              }}
            >
              <ListItemText
                primary={"User Manager"}
                className="text-primary-color"
              />
            </ListItem>
            <ListItem
              button
              key={"Device Manager"}
              onClick={() => {
                history.push("/devicemanager");
              }}
            >
              <ListItemText
                primary={"Device Manager"}
                className="text-primary-color"
              />
            </ListItem>
            <ListItem
              button
              key={"Device Token"}
              onClick={() => {
                history.push("/devicetoken");
              }}
            >
              <ListItemText
                primary={"Device Token"}
                className="text-primary-color"
              />
            </ListItem>
            <ListItem
              button
              key={"QR Code"}
              onClick={() => {
                history.push("/qrcode");
              }}
            >
              <ListItemText
                primary={"QR Code"}
                className="text-primary-color"
              />
            </ListItem>
            <ListItem
              button
              key={"Firmware Manager"}
              onClick={() => {
                history.push("/firmware_manager");
              }}
            >
              <ListItemText
                primary={"Firmware Manager"}
                className="text-primary-color"
              />
            </ListItem>
            <ListItem
              button
              key={"System Logs"}
              onClick={() => {
                history.push("/systemlogs");
              }}
            >
              <ListItemText
                primary={"System Logs"}
                className="text-primary-color"
              />
            </ListItem>
            <ListItem
              button
              key={"Logout"}
              onClick={() => {
                deleteCookie("admintok");
                history.push("/");
              }}
            >
              <ListItemText primary={"Logout"} className="text-primary-color" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </nav>
  );
}
