import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
// import { Button } from "@material-ui/core";
// import HomeIcon from "@material-ui/icons/Home";
import "./Main.css";
import "./../App.css";
export default function RegisterdDevice() {
  const history = useHistory();
  let [state, setState] = useState({ devicedata: [], filter: "" });
  const columns = [
    {
      field: "machinetype",
      headerName: "Machinetype",
      width: 250,
      editable: false,
    },
    {
      field: "serial",
      headerName: "SerialID",
      width: 250,
      editable: false,
    },
  ];
  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/machinedata", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
      let data = await response.json();
      data = data.map((v, i) => {
        return { ...v, id: i };
      });
      setState({
        ...state,
        devicedata: data,
      });
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div>
      <div className="admin-tab">
        <Navbar logout={true} title={"Registered Device"} />
      </div>
      <div className="container">
        <div className="manage-console">
          <div
            style={{
              minHeight: "400px",
              width: "68%",
              maxWidth: "800px",
              marginTop: "10px",
            }}
          >
            {/* <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="default"
                style={{
                  height: "48px",
                  marginTop: "10px",
                  marginBottom: "10px",
                  backgroundColor: "#ffffff",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  // margin: "5px",
                }}
                startIcon={<HomeIcon />}
                onClick={() => window.close()}
              >
                Home
              </Button>
            </div> */}

            <DataGrid
              rows={state.devicedata}
              columns={columns}
              pageSize={5}
              components={{
                Toolbar: GridToolbar,
              }}
              //   disableSelectionOnClick
            />
          </div>
        </div>
      </div>
    </div>
  );
}
