import React, { useState, useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";
import QRCode from "qrcode";

export default function QRCodeGen(props) {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState("");
  const [state, setState] = React.useState({
    // QR CODE
    qrInput: Boolean,
    qr: "",
  });

  const handleQR = () => {
    if (state.qr === "") {
      setState({
        ...state,
        qrInput: false,
      });
    } else {
      setState({
        ...state,
        qrInput: Boolean,
      });
      generateQrCode();
    }
  };

  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL(state.qr);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
    }
  }

  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"QR Code"} />
        </div>

        <div className="manage-console">
          <div
            className="console"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "auto",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <TextField
                label="Serial ID"
                // onChange={(e) => setText(e.target.value)}
                style={{ width: "80%" }}
                error={!state.qrInput}
                helperText={!state.qrInput && "Please specify serial ID"}
                name="qr"
                value={state.qr}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleQR}>
                        <CheckCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <br />
              <div style={{ width: "100%" }}>
                <Button variant="contained" onClick={handleQR}>
                  Generate
                </Button>
              </div> */}
            {/* <br /> */}
            {imageUrl && (
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <a href={imageUrl} download={state.qr}>
                  <img src={imageUrl} alt="img" style={{ width: "30%" }} />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
