import config from "./config.json";
import { AESencrypt, AESdecrypt } from "./AESEncoder";
export function createCookie(cookieName, cookieValue, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000 * 0.5);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie =
    cookieName +
    "=" +
    (AESencrypt(cookieValue, config.aes_token_key) || "") +
    expires +
    ";path=/;";
}
export function deleteCookie(name) {
  document.cookie = name + "=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;";
}
export function accessCookie(cookieName) {
  var name = cookieName + "=";
  var allCookieArray = document.cookie.split(";");
  var last = "";
  for (var i = 0; i < allCookieArray.length; i++) {
    var temp = allCookieArray[i].trim();

    if (temp.indexOf(name) === 0) {
      last = temp.substring(name.length, temp.length);
    }
  }
  return AESdecrypt(last, config.aes_token_key);
}
