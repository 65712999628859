import React, { useState, useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import { TextField, Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SendIcon from "@mui/icons-material/Send";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";

export default function FirmwareManager(props) {
  const history = useHistory();
  const [state, setState] = useState({
    machinetype: "",
    machineInput: true,
    firmware_name: "",
    firmwareInput: true,
    firmware_version: "",
    versionInput: true,
    filebytes: "",
    firmware_data: [],
    config_machinetype: [],
  });
  let columns = [
    {
      field: "machinetype",
      headerName: "Machinetype",
      width: 200,
      editable: false,
    },
    {
      field: "firmware_name",
      headerName: "FirmwareName",
      width: 200,
      editable: false,
    },
    {
      field: "firmware_version",
      headerName: "FirmwareVersion",
      width: 200,
      editable: false,
    },
  ];

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "firmware_name" && event.target.value.includes("_")) {
    } else if (
      name === "firmware_version" &&
      !/^\d+$/.test(event.target.value) &&
      event.target.value !== ""
    ) {
    } else {
      console.log(
        state.firmware_data.filter((v) => {
          return (
            v["firmware_name"] ===
              (name === "firmware_name"
                ? event.target.value
                : state.firmware_name) &&
            v["firmware_version"] ===
              (name === "firmware_version"
                ? event.target.value
                : state.firmware_version)
          );
        }).length
      );
      setState({
        ...state,
        [name]: event.target.value,
        firmwareInput:
          name === "firmware_name"
            ? event.target.value !== ""
            : state.firmwareInput,
        versionInput:
          (name === "firmware_version"
            ? event.target.value !== ""
            : state.versionInput) &&
          state.firmware_data.filter((v) => {
            return (
              v["firmware_name"] ===
                (name === "firmware_name"
                  ? event.target.value
                  : state.firmware_name) &&
              v["firmware_version"] ===
                (name === "firmware_version"
                  ? event.target.value
                  : state.firmware_version)
            );
          }).length === 0,
      });
    }
  };

  const handleFileLoader = ({ target }) => {
    const fileReader = new FileReader();
    const name = target.name;
    // data:application/octet-stream;base64,
    fileReader.readAsDataURL(target.files[0]);
    fileReader.onload = (e) => {
      if (
        e.target.result.slice(0, 37) === "data:application/octet-stream;base64,"
      ) {
        setState({ ...state, [name]: e.target.result.slice(37) });
      } else {
        alert("Please upload binary file.");
      }
    };
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
    }
  }
  async function uploadFirmware() {
    if (
      state.machinetype === null ||
      state.machinetype === "" ||
      state.firmware_name === "" ||
      state.firmware_version === "" ||
      state.firmware_data.filter((v) => {
        return (
          v["firmware_name"] === state.firmware_name &&
          v["firmware_version"] === state.firmware_version
        );
      }).length !== 0
    ) {
      setState({
        ...state,
        machineInput: state.machinetype !== null && state.machinetype !== "",
        firmwareInput: state.firmware_name !== "",
        versionInput:
          state.firmware_version !== "" &&
          state.firmware_data.filter((v) => {
            return (
              v["firmware_name"] === state.firmware_name &&
              v["firmware_version"] === state.firmware_version
            );
          }).length === 0,
      });
    } else if (
      !state.machineInput ||
      !state.firmwareInput ||
      !state.versionInput
    ) {
      alert("Please fill form!");
    } else if (state.filebytes === "") {
      alert("Please upload file!");
    } else {
      let response = await fetch(config.backend_url + "/upload_firmware", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
        body: JSON.stringify({
          machinetype: state.machinetype,
          firmware_name: state.firmware_name,
          firmware_version: state.firmware_version,
          filebytes: state.filebytes,
        }),
      });
      if (response.status !== 200) {
        let data = await response.json();
        alert(JSON.stringify(data));
      } else {
        alert("Upload Success!");
        setState({
          ...state,
          machinetype: "",
          machineInput: true,
          firmware_name: "",
          firmwareInput: true,
          firmware_version: "",
          versionInput: true,
          filebytes: "",
          firmware_data: [],
        });
      }
    }
  }
  async function getFirmwareversions(machinetype) {
    let response = await fetch(
      config.backend_url + "/get_firmware_versions?machinetype=" + machinetype,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status !== 200) {
      return [];
      // setState({
      //   ...state,
      //   firmware_data: [],
      // });
    } else {
      let data = await response.json();
      data = data.map((v, i) => {
        return { ...v, id: i };
      });
      // setState({
      //   ...state,
      //   firmware_data: data,
      // });
      return data;
    }
  }
  async function getMachineType() {
    let response = await fetch(
      config.backend_url + "/be/admin/get_machinetype",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      setState({
        ...state,
        config_machinetype: data,
      });
    } else {
    }
  }

  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
      getMachineType();
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"Firmware Manager"} />
        </div>

        <div className="manage-console">
          <div
            className="console"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "auto",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Autocomplete
                options={state.config_machinetype}
                getOptionLabel={(option) => option}
                onChange={async (event, value) => {
                  let firmware_data =
                    value !== null ? await getFirmwareversions(value) : [];
                  setState({
                    ...state,
                    machinetype: value,
                    machineInput: value !== null,
                    firmware_data: firmware_data,
                  });
                }}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!state.machineInput}
                    name="machinetype"
                    value={state.machinetype}
                    onChange={handleChange}
                    label="Machine Type"
                    helperText={!state.machineInput && "Please specify machine"}
                    style={{
                      // display: "flex",
                      alignItems: "right",
                      width: "100%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
              />

              <TextField
                error={!state.firmwareInput}
                name="firmware_name"
                value={state.firmware_name}
                onChange={handleChange}
                label="Firmware Name"
                helperText={
                  !state.firmwareInput && "Please specify Firmware Name"
                }
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
              <TextField
                error={!state.versionInput}
                name="firmware_version"
                value={state.firmware_version}
                onChange={handleChange}
                label="Firmware Version"
                helperText={
                  !state.versionInput &&
                  (state.firmware_version === ""
                    ? "Please specify Firmware Version"
                    : "Cannot use this Firmware Version")
                }
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <div>
                {/* <Button variant="contained" component="label">
                  Upload
                  <input hidden accept="image/*" multiple type="file" />
                </Button> */}
                <Button
                  variant="contained"
                  component="label"
                  color="default"
                  style={{
                    height: "48px",
                    marginTop: "10px",
                    marginRight: "10px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    // margin: "5px",
                  }}
                  startIcon={
                    state.filebytes === "" ? (
                      <FileUploadIcon />
                    ) : (
                      <CheckCircleIcon color="success" />
                    )
                  }
                >
                  Upload File
                  <input
                    hidden
                    multiple
                    type="file"
                    name="filebytes"
                    onChange={handleFileLoader}
                  />
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="default"
                  style={{
                    height: "48px",
                    marginTop: "10px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    // margin: "5px",
                  }}
                  endIcon={<SendIcon />}
                  onClick={uploadFirmware}
                >
                  Upload
                </Button>
              </div>
            </div>
            {state.firmware_data.length !== 0 && (
              <div
                style={{
                  minHeight: "400px",
                  width: "100%",
                  maxWidth: "800px",
                  marginTop: "10px",
                }}
              >
                <DataGrid
                  rows={state.firmware_data}
                  columns={columns}
                  pageSize={5}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  //   disableSelectionOnClick
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
