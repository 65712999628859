import React, { useState } from "react";
import "./../App.css";
// import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PersonIcon from "@material-ui/icons/Person";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
// import Typography from "@material-ui/core/Typography";
// import Backdrop from "@material-ui/core/Backdrop";
// import Popper from "@material-ui/core/Popper";
// import Button from "@material-ui/core/Button";
// import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import config from "./config.json";
// import { render } from "@testing-library/react";

export default function Register(props) {
  const history = useHistory();
  // const [open, setOpen] = useState(false);

  //===================== FOR BACKDROP =======================
  // const useStyles = makeStyles((theme) => ({
  //   backdrop: {
  //     zIndex: theme.zIndex.drawer + 1,
  //     color: "#fff",
  //   },
  // }));
  // const classes = useStyles();
  const [bdOpen, setBdOpen] = React.useState(false);

  //==========================================================

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "username") {
      if (value.length >= 4) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      }
    } else if (name === "userpwd") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "cnfrmpwd") {
      if (value !== state.userpwd || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };

  let [state, setState] = useState({
    username: "",
    userpwd: "",
    cnfrmpwd: "",
  });
  let [validstate, setValidstate] = useState({
    username: true,
    userpwd: true,
    cnfrmpwd: true,
  });

  //==========================================================

  function check_valid(name) {
    let value = state[name];
    if (name === "username") {
      if (value.length >= 4) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      }
    } else if (name === "userpwd") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "cnfrmpwd") {
      if (value !== state.userpwd || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  }

  async function sentRegister() {
    //wait Object.keys(state).map(check_valid);
    await Object.keys(state).map(check_valid);
    if (Object.values(validstate).reduce((a, b) => a + b, 0) === 3) {
      let response = await fetch(config.backend_url + "/be/admin/register", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: JSON.stringify({
          username: state.username,
          password: state.userpwd,
        }),
      });
      if (response.status === 200) {
        setBdOpen(true);
      } else {
        document.getElementById("warning").innerHTML = "Please Try again";
      }
    }
  }

  //==========================================================

  return (
    <div>
      {/* <Navbar logout={false} /> */}
      {/* { JSON.stringify( {
        username: state.username,
        userpwd: state.userpwd,
        cnfrmpwd: state.cnfrmpwd,
      }) } */}

      {!bdOpen ? (
        <div className="container home-block">
          <div className="home-card">
            <div className="text-title">Register to BSE Admin</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <PersonIcon />
                <TextField
                  error={!validstate.username}
                  label="Username"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  value={state.username}
                  name="username"
                  onChange={handleChange}
                  helperText={
                    !validstate.username && "Cannot use this username."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <VpnKeyIcon />
                <TextField
                  label="Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  value={state.userpwd}
                  name="userpwd"
                  onChange={handleChange}
                  error={!validstate.userpwd}
                  helperText={
                    !validstate.userpwd && "Password must be 6-14 characters."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <VpnKeyIcon />
                <TextField
                  label="Confirm Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  value={state.cnfrmpwd}
                  name="cnfrmpwd"
                  onChange={handleChange}
                  error={!validstate.cnfrmpwd}
                  helperText={
                    !validstate.cnfrmpwd && "Confirm password does not matched."
                  }
                />
              </div>
            </div>

            <div className="space-text">
              <div
                className="text-option"
                id="warning"
                style={{ color: "red" }}
              ></div>
            </div>
            <div
              className="button"
              style={{ cursor: "pointer", color: "white" }}
              onClick={sentRegister}
            >
              Register
            </div>

            <div
              className="button-border"
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
            >
              <div className="button-text">Back</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container home-block">
          <div className="home-card">
            <div className="text-title">Register Success!!!</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                Please Contact System Admin to Activete Your Account
              </div>
            </div>
            <div
              className="button"
              style={{ cursor: "pointer" }}
              onClick={() => history.replace("/")}
            >
              <div className="button-text-white">OK</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
