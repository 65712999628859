import React, { useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";

export default function DeviceToken(props) {
  const history = useHistory();
  const [state, setState] = React.useState({
    //serial token
    gettokenserial: "",
    generatedtoken: "",
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
    }
  }
  async function getdevicetoken() {
    let response = await fetch(config.backend_url + "/getdevicetoken", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        serialid: state.gettokenserial,
      }),
    });
    if (response.status === 200) {
      let data = await response.json();
      setState({
        ...state,
        generatedtoken: data.token,
      });
    } else {
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"Device Token"} />
        </div>

        <div className="manage-console">
          <div
            className="console"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "auto",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <TextField
                style={{ width: "80%" }}
                label="Serial ID"
                // onChange={(e) => setText(e.target.value)}
                name="gettokenserial"
                value={state.gettokenserial}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={getdevicetoken}>
                        <CheckCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {state.generatedtoken !== "" && (
              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  style={{ width: "80%" }}
                  label="Token"
                  value={state.generatedtoken}
                  multiline
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton>
                  //         {/* <ContentCopyIcon /> */}
                  //         <CheckCircleIcon />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
