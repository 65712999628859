import React, { useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import { Button, Typography, Backdrop, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SendIcon from "@mui/icons-material/Send";

import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";

import Autocomplete from "@material-ui/lab/Autocomplete";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
// import { RegisterdDevice } from "./../components";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function DeviceManager(props) {
  const history = useHistory();

  const [deviceOpen, setDeviceOpen] = React.useState(false);
  const classes = useStyles();

  const [state, setState] = React.useState({
    // Tabs
    device_tab: "add_serial", // ["add_serial","update_firmware"]
    // DEVICE MANAGER
    machinetype: "",
    serialid: "",
    machineInput: Boolean,
    serialInput: Boolean,
    machineExist: Boolean,
    serialExist: Boolean,
    confirmAdd: false,
    // Firmware Manager
    device_list: [],
    update_serial_firmware: "",
    update_machine_firmware: "",
    firmware_name: "",
    firmware_version: "",
    firmware_list: [],
    current_device_version: "",

    config_machinetype: [],
  });

  const handleAdd = () => {
    if (state.machinetype === "") {
      if (state.serialid === "") {
        setState({
          ...state,
          machineInput: false,
          serialInput: false,
        });
      } else {
        setState({
          ...state,
          machineInput: false,
          serialInput: true,
        });
      }
    } else if (state.serialid === "") {
      if (state.machinetype === "") {
        setState({
          ...state,
          machineInput: false,
          serialInput: false,
        });
      } else {
        setState({
          ...state,
          machineInput: true,
          serialInput: false,
        });
      }
    } else {
      setState({
        ...state,
        machineInput: true,
        serialInput: true,
      });
      setDeviceOpen(!deviceOpen);
    }
  };

  async function handleDeviceChange() {
    let response = await fetch(config.backend_url + "/be/addmachine", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        machinetype: state.machinetype,
        serialid: state.serialid,
      }),
    });
    const data = await response.json();
    if (response.status !== 200) {
      if (data.detail === "Machine Type not found") {
        setState({
          ...state,
          machineExist: false,
          confirmAdd: true,
        });
      } else if (data.detail === "This serial ID has already been used") {
        setState({
          ...state,
          machineExist: true,
          serialExist: true,
          confirmAdd: true,
        });
      }
    } else {
      setState({
        ...state,
        machineExist: true,
        serialExist: false,
        confirmAdd: true,
      });
      // setDeviceOpen(!deviceOpen);
    }
  }

  const handleDeviceClose = () => {
    setDeviceOpen(false);
    setState({
      ...state,
      confirmAdd: false,
      machineExist: Boolean,
      serialExist: Boolean,
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
      let config_machinetype = await getMachineType();
      let device_list = await getDeviceList();
      setState({
        ...state,
        device_list: device_list,
        config_machinetype: config_machinetype,
      });
    }
  }
  async function getMachineType() {
    let response = await fetch(
      config.backend_url + "/be/admin/get_machinetype",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      // setState({
      //   ...state,
      //   config_machinetype: data,
      // });
      return data;
    } else {
      return [];
    }
  }
  async function getDeviceList() {
    let response = await fetch(
      config.backend_url + "/be/admin/get_serial_list",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      return data;
      // setState({
      //   ...state,
      //   device_list: data,
      // });
    } else {
      return [];
    }
  }
  async function getFirmwareversions(machinetype) {
    let response = await fetch(
      config.backend_url + "/get_firmware_versions?machinetype=" + machinetype,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status !== 200) {
      return [];
      // setState({
      //   ...state,
      //   firmware_data: [],
      // });
    } else {
      let data = await response.json();
      data = data.map((v, i) => {
        return { ...v, id: i };
      });
      // setState({
      //   ...state,
      //   firmware_data: data,
      // });
      return data;
    }
  }
  async function sendUpdateFirmware() {
    let response = await fetch(config.backend_url + "/update_firmware", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        serialid: state.update_serial_firmware,
        machinetype: state.update_machine_firmware,
        firmware_name: state.firmware_name,
        firmware_version: state.firmware_version,
        firmware_list: [],
      }),
    });
    if (response.status !== 200) {
    } else {
      alert("Send Update Firmware Success!");
      setState({
        ...state,
        update_serial_firmware: "",
        update_machine_firmware: "",
        firmware_name: "",
        firmware_version: "",
        current_device_version: "",
      });
    }
  }
  async function getDeviceFirmwareversion(serial) {
    let response = await fetch(
      config.backend_url +
        "/be/admin/getdevicefirmwareversion?serialid=" +
        serial,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status !== 200) {
      return "";
      // setState({
      //   ...state,
      //   current_device_version: "",
      // });
    } else {
      // setState({
      //   ...state,
      //   current_device_version: "",
      // });
      // {'serialid':df_data['serial'], 'version':df_data['version'],'lastest_update':df_data['lastest_update']}
      let data = await response.json();
      return `SerialID: ${data.serialid}, Version: ${data.version}, Lastest Update: ${data.lastest_update}`;
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"Device Manager"} />
        </div>

        <div className="manage-console">
          <div
            className="console"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // height: "40vh",
              alignItems: "center",
            }}
          >
            <Backdrop className={classes.backdrop} open={deviceOpen}>
              <div
                className="data-card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflowX: "hidden",
                }}
              >
                <div className="card-con">
                  {state.machineExist === false ? (
                    <div>
                      <ErrorIcon
                        fontSize="large"
                        style={{ color: "black" }}
                      ></ErrorIcon>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        gutterBottom
                        style={{ fontWeight: "bold" }}
                      >
                        Machine Type not found.
                      </Typography>
                    </div>
                  ) : (
                    <div className="card-con">
                      {state.serialExist === true ? (
                        <div>
                          <ErrorIcon
                            fontSize="large"
                            style={{ color: "black" }}
                          ></ErrorIcon>
                          <Typography
                            color="textPrimary"
                            variant="h6"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            This serial ID has already been used.
                          </Typography>
                        </div>
                      ) : (
                        <div className="card-con">
                          {state.machineExist === true &&
                          state.serialExist === false ? (
                            <div>
                              <CheckCircleIcon
                                fontSize="large"
                                style={{ color: "black" }}
                              ></CheckCircleIcon>
                              <Typography
                                color="textPrimary"
                                variant="h6"
                                gutterBottom
                                style={{ fontWeight: "bold" }}
                              >
                                Machine added
                              </Typography>
                            </div>
                          ) : (
                            <div className="card-con">
                              <AssignmentIcon
                                fontSize="large"
                                style={{ color: "black" }}
                              ></AssignmentIcon>
                              <Typography
                                color="textPrimary"
                                variant="h6"
                                gutterBottom
                                style={{ fontWeight: "bold" }}
                              >
                                Please verify the information before adding
                                machine.
                              </Typography>
                              <div className="device-manager-card">
                                <div className="data-con">
                                  <b className="machine-type">
                                    {"Machine type: " + state.machinetype}
                                  </b>
                                  <b className="serial-name">
                                    {"Serial ID: " + state.serialid}
                                  </b>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div>
                  {state.confirmAdd === false ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          width: "50%",
                          height: "48px",
                          marginTop: "10px",
                          backgroundColor: "#3f51b5",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "white",
                          margin: "5px",
                        }}
                        onClick={handleDeviceChange}
                      >
                        Confirm
                      </Button>
                      <Button
                        style={{
                          width: "50%",
                          height: "48px",
                          marginTop: "10px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "5px",
                        }}
                        onClick={handleDeviceClose}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          width: "100%",
                          height: "48px",
                          marginTop: "10px",
                          backgroundColor: "#e0e0e0",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "5px",
                        }}
                        onClick={handleDeviceClose}
                      >
                        Close
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Backdrop>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="text-subtitle" style={{ paddingLeft: 20 }}>
                Add Device
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Autocomplete
                options={state.config_machinetype}
                getOptionLabel={(option) => option}
                // value={state.machinetype}
                // onChange={handleChange}
                onChange={(event, value) => {
                  setState({
                    ...state,
                    machinetype: value,
                  });
                }}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!state.machineInput}
                    name="machinetype"
                    value={state.machinetype}
                    onChange={handleChange}
                    label="Machine Type"
                    helperText={!state.machineInput && "Please specify machine"}
                    style={{
                      // display: "flex",
                      alignItems: "right",
                      width: "100%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
              />

              <TextField
                error={!state.serialInput}
                name="serialid"
                value={state.serialid}
                onChange={handleChange}
                label="Serial ID"
                helperText={!state.serialInput && "Please specify serial ID"}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <div>
                <Button
                  variant="contained"
                  color="default"
                  style={{
                    height: "48px",
                    marginTop: "10px",
                    marginRight: "10px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    // margin: "5px",
                  }}
                  startIcon={<DevicesOtherIcon />}
                  onClick={() =>
                    // window.open("/RegisterdDevice", "_blank").focus()
                    history.push("/RegisterdDevice")
                  }
                >
                  Registered Device
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="default"
                  style={{
                    height: "48px",
                    marginTop: "10px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    // margin: "5px",
                  }}
                  startIcon={<AddCircleIcon />}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
          <div
            className="console"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // height: "40vh",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div className="text-subtitle" style={{ paddingLeft: 20 }}>
                Firmware Manager
              </div>
            </div>
            {/* {JSON.stringify(
              state.device_list.map((v) => {
                return v.serial;
              })
            )} */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Autocomplete
                options={state.device_list.map((v, i) => {
                  return v["serial"];
                })}
                getOptionLabel={(option) => option}
                // value={state.machinetype}
                // onChange={handleChange}
                onChange={async (event, value) => {
                  let device_filter = state.device_list.filter((v, i) => {
                    return v["serial"] === value;
                  });
                  let firmware_list =
                    device_filter.length === 1 &&
                    device_filter[0].machinetype !==
                      state.update_machine_firmware
                      ? await getFirmwareversions(device_filter[0].machinetype)
                      : state.firmware_list;
                  // Call device API
                  // let current_device_version = state.device_list.includes(value)
                  //   ? await getDeviceFirmwareversion(value)
                  //   : "";
                  let current_device_version = state.device_list
                    .map((v, i) => {
                      return v["serial"];
                    })
                    .includes(value)
                    ? await getDeviceFirmwareversion(value)
                    : "";

                  setState({
                    ...state,
                    update_serial_firmware: value,
                    update_machine_firmware:
                      device_filter.length === 1
                        ? device_filter[0].machinetype
                        : "",
                    firmware_name: null,
                    // device_filter.length === 1 &&
                    // device_filter[0].machinetype !==
                    //   state.update_machine_firmware
                    //   ? ""
                    //   : state.firmware_name,
                    firmware_version: null,
                    // device_filter.length === 1 &&
                    // device_filter[0].machinetype !==
                    //   state.update_machine_firmware
                    //   ? ""
                    //   : state.firmware_version,
                    firmware_list: firmware_list,
                    current_device_version: current_device_version,
                  });
                }}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                value={
                  state.update_serial_firmware === "" ||
                  state.update_serial_firmware === null
                    ? ""
                    : state.update_serial_firmware
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={!state.machineInput}
                    name="update_serial_firmware"
                    // onChange={handleChange}
                    label="Serial ID"
                    // helperText={!state.machineInput && "Please specify machine"}
                    style={{
                      // display: "flex",
                      alignItems: "right",
                      width: "100%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
              />

              <TextField
                // error={!state.serialInput}
                disabled
                name="update_machine_firmware"
                value={state.update_machine_firmware}
                // onChange={handleChange}
                label="Machine Type"
                // helperText={!state.serialInput && "Please specify serial ID"}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Autocomplete
                disabled={
                  state.update_machine_firmware === "" ||
                  state.update_machine_firmware === null
                }
                options={[
                  ...new Set(
                    state.firmware_list.map((v, i) => {
                      return v["firmware_name"];
                    })
                  ),
                ]}
                getOptionLabel={(option) => option}
                // value={state.machinetype}
                // onChange={handleChange}
                onChange={async (event, value) => {
                  setState({
                    ...state,
                    firmware_name: value,
                    firmware_version: null,
                    // value === state.firmware_name
                    //   ? state.firmware_version
                    //   : "",
                  });
                }}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                value={
                  state.firmware_name === "" || state.firmware_name === null
                    ? ""
                    : state.firmware_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={!state.machineInput}
                    name="firmware_name"
                    // onChange={handleChange}
                    label="Firmware Name"
                    // helperText={!state.machineInput && "Please specify machine"}
                    style={{
                      // display: "flex",
                      alignItems: "right",
                      width: "100%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
              />
              <Autocomplete
                disabled={
                  state.firmware_name === "" || state.firmware_name === null
                }
                options={state.firmware_list
                  .filter((v, i) => {
                    return v["firmware_name"] === state.firmware_name;
                  })
                  .map((v, i) => {
                    return v["firmware_version"].toString();
                  })}
                getOptionLabel={(option) => option}
                // value={state.machinetype}
                // onChange={handleChange}
                onChange={async (event, value) => {
                  setState({
                    ...state,
                    firmware_version: value,
                  });
                }}
                style={{
                  // display: "flex",
                  alignItems: "right",
                  width: "100%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
                value={
                  state.firmware_version === "" ||
                  state.firmware_version === null
                    ? ""
                    : state.firmware_version
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // error={!state.machineInput}
                    name="firmware_version"
                    // onChange={handleChange}
                    label="Firmware Version"
                    // helperText={!state.machineInput && "Please specify machine"}
                    style={{
                      // display: "flex",
                      alignItems: "right",
                      width: "100%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: "10px",
              }}
            >
              {state.current_device_version !== "" &&
                (state.update_serial_firmware !== "" ||
                  state.update_serial_firmware !== null) && (
                  <div className="text-plain" style={{ paddingLeft: 20 }}>
                    {state.current_device_version}
                  </div>
                )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                // marginTop: "10px",
              }}
            >
              <div>
                <Button
                  disabled={
                    state.firmware_version === "" ||
                    state.firmware_version === null
                  }
                  variant="contained"
                  color="default"
                  style={{
                    height: "48px",
                    // marginTop: "10px",
                    backgroundColor: "#ffffff",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    // margin: "5px",
                  }}
                  endIcon={<SendIcon />}
                  onClick={sendUpdateFirmware}
                >
                  Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
