import React, { useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import {
  FormControl,
  ListItem,
  ListItemText,
  Button,
  Switch,
  Typography,
  Backdrop,
  MenuItem,
  TextField,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import SearchSharpIcon from "@material-ui/icons/SearchSharp";

import AutorenewIcon from "@material-ui/icons/Autorenew";
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function UserManager(props) {
  const history = useHistory();
  const [click, setClick] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClick = () => setClick(!click);

  const [state, setState] = React.useState({
    // USER MANAGER
    loading: Boolean,
    userExist: Boolean,
    searchType: "Username",
    searchWord: "",
    user: "",
    searchInput: false,
    controlAccess: "",
    serial: {},
    datacard: "",
  });

  async function searchUser() {
    await setState({ ...state, loading: true });

    if (state.searchWord === "") {
      setState({ ...state, loading: false, searchInput: false });
    } else {
      let response = await fetch(config.backend_url + "/be/getuserinfo", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + accessCookie("admintok"),
        },
        body: JSON.stringify({
          search_word: state.searchWord,
          search_type: state.searchType,
        }),
      });
      const data = await response.json();
      if (data.details === "User not found") {
        setState({
          ...state,
          searchInput: true,
          loading: false,
          userExist: false,
        });
      } else {
        await setState({
          ...state,
          user: data,
          loading: false,
          searchInput: true,
          userExist: true,
        });
      }
    }
  }

  async function handleDatacard(data) {
    let serialData = Object.values(JSON.parse(data.serial));
    for (var i = 0; i < Object.keys(JSON.parse(data.serial)).length; i++) {
      serialData[i]["testserial"] = Object.keys(JSON.parse(data.serial))[i];
    }
    await setState({
      ...state,
      datacard: data,
      controlAccess: data.controlaccess,
      serial: serialData,
    });
    setOpen(!open);
  }

  const handleSwitch = (data) => {
    let status = JSON.parse(state.controlAccess);
    if (!status.includes(data)) {
      status.push(data);
    } else {
      const index = status.indexOf(data);
      status.splice(index, 1);
    }

    status = JSON.stringify(status.sort());
    setState({
      ...state,
      controlAccess: status,
    });
  };

  async function changeControlAccess() {
    let response = await fetch(config.backend_url + "/be/useraccess", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        username: state.datacard.username,
        controlaccess: state.controlAccess,
      }),
    });
    if (response.status === 200) {
      let userData = state.user;
      for (var i = 0; i < userData.length; i++) {
        if (userData[i]["username"] === state.datacard.username) {
          userData[i]["controlaccess"] = state.controlAccess;
        }
      }
      setState({
        ...state,
        user: userData,
      });
      setOpen(false);
    }
  }

  async function handleClose() {
    await setOpen(false);
    setState({
      ...state,
      controlAccess: state.datacard.controlaccess,
    });
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
    }
  }

  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"User Manager"} />
        </div>
        <div className="manage-console">
          <div
            className="console"
            style={{ height: "70vh", overflowY: "scroll" }}
          >
            <Backdrop className={classes.backdrop} open={open}>
              <div
                className="data-card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflowX: "hidden",
                  overflowY: "hidden",
                  height: "90vh",
                  maxWidth: "500px",
                  width: "80%",
                }}
              >
                {state.searchInput === false ? (
                  <div></div>
                ) : (
                  <div>
                    {state.userExist === false ? (
                      <div></div>
                    ) : (
                      <div>
                        {state.datacard === "" ? (
                          <div></div>
                        ) : (
                          <div className="card-con">
                            <div className="card-text">
                              <Typography
                                color="textPrimary"
                                style={{ fontWeight: "bold" }}
                              >
                                {JSON.stringify(
                                  state.datacard.username
                                ).replace(/['"]+/g, "")}
                              </Typography>
                              <Typography
                                color="textSecondary"
                                style={{ fontWeight: "bold" }}
                              >
                                {JSON.stringify(
                                  state.datacard.userfirstname
                                ).replace(/['"]+/g, "") +
                                  " " +
                                  JSON.stringify(
                                    state.datacard.userlastname
                                  ).replace(/['"]+/g, "")}{" "}
                                |{" "}
                                {JSON.stringify(
                                  state.datacard.useremail
                                ).replace(/['"]+/g, "")}
                              </Typography>
                            </div>
                            <hr />
                            {state.serial !== {} && (
                              <div
                                style={{
                                  overflowX: "hidden",
                                  padding: "30px",
                                  width: "100%",
                                  height: "60vh",
                                }}
                              >
                                {state.serial.map((serialdata) => (
                                  <div className="user-manager-card">
                                    <div className="data-con">
                                      <b className="serial-name">
                                        {"Serial Name: " +
                                          serialdata.serialname}
                                      </b>
                                      <b className="serial-name">
                                        {"Serial ID: " + serialdata.testserial}
                                      </b>
                                      <b className="machine-type">
                                        {"Machine type: " +
                                          serialdata.machinetype}
                                      </b>
                                    </div>
                                    {JSON.parse(state.controlAccess).includes(
                                      serialdata.testserial
                                    ) ? (
                                      <Switch
                                        checked={true}
                                        onChange={handleSwitch.bind(
                                          this,
                                          serialdata.testserial
                                        )}
                                        statecolor="primary"
                                      />
                                    ) : (
                                      <Switch
                                        style={{
                                          display: "flex",
                                          justifyContent: "right",
                                          alignItems: "right",
                                          float: "right",
                                        }}
                                        checked={false}
                                        onChange={handleSwitch.bind(
                                          this,
                                          serialdata.testserial
                                        )}
                                        statecolor="primary"
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      width: "50%",
                      height: "48px",
                      marginTop: "10px",
                      backgroundColor: "#3f51b5",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      margin: "5px",
                    }}
                    onClick={changeControlAccess}
                  >
                    OK
                  </Button>

                  <Button
                    style={{
                      width: "50%",
                      height: "48px",
                      marginTop: "10px",
                      backgroundColor: "#e0e0e0",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "5px",
                    }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Backdrop>

            <div>
              <div className="search-element">
                <div className="mobile-search-type" onClick={handleClick}>
                  {/* <MenuIcon /> */}
                  <div className={click ? <div></div> : "mobile-menu"}>
                    <Select
                      name="searchType"
                      defaultValue="Username"
                      value={state.searchType}
                      onChange={handleChange}
                    >
                      {click ? setClick(false) : <div></div>}
                      <MenuItem value={"Username"}>Username</MenuItem>
                      <MenuItem value={"Email"}>Email</MenuItem>
                      <MenuItem value={"Firstname"}>Firstname</MenuItem>
                      <MenuItem value={"Lastname"}>Lastname</MenuItem>
                    </Select>
                  </div>
                </div>
                <FormControl style={{ minWidth: 100, marginTop: 8 }}>
                  <Select
                    className="search-type"
                    name="searchType"
                    defaultValue="Username"
                    value={state.searchType}
                    onChange={handleChange}
                  >
                    <MenuItem value={"Username"}>Username</MenuItem>
                    <MenuItem value={"Email"}>Email</MenuItem>
                    <MenuItem value={"Firstname"}>Firstname</MenuItem>
                    <MenuItem value={"Lastname"}>Lastname</MenuItem>
                  </Select>
                </FormControl>
                <div className="search-bar">
                  <TextField
                    name="searchWord"
                    value={state.searchWord}
                    onChange={handleChange}
                    style={{ width: "100%", padding: "0px 5px" }}
                    placeholder="  Search..."
                    InputProps={{ disableUnderline: true }}
                  />
                </div>
                <div className="search-button">
                  <Button startIcon={<SearchSharpIcon />} onClick={searchUser}>
                    Search
                  </Button>
                </div>
                <div className="mobile-search-button" onClick={searchUser}>
                  <SearchSharpIcon />
                </div>
              </div>
            </div>

            <div>
              {state.loading === true ? (
                <h2>
                  <AutorenewIcon />
                  Loading...
                </h2>
              ) : (
                <div>
                  {state.searchInput === false ? (
                    <div></div>
                  ) : (
                    <div>
                      {state.userExist === false ? (
                        <h2>User not found</h2>
                      ) : (
                        <div>
                          {state.user === "" ? (
                            <h2>Error, Please try again</h2>
                          ) : (
                            state.user.map((data) => (
                              <ListItem
                                button
                                onClick={handleDatacard.bind(this, data)}
                              >
                                <ListItemText
                                  primary={
                                    "Username: " +
                                    JSON.stringify(data.username).replace(
                                      /['"]+/g,
                                      ""
                                    )
                                  }
                                  secondary={
                                    "Email: " +
                                    JSON.stringify(data.useremail).replace(
                                      /['"]+/g,
                                      ""
                                    )
                                  }
                                />
                              </ListItem>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
