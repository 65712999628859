import React, { useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@mui/icons-material/Person";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import MonitorIcon from "@mui/icons-material/Monitor";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";

import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";

export default function SystemLogs(props) {
  const history = useHistory();

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
    }
  }

  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"System Logs"} />
        </div>

        <div className="manage-console">
          <div
            className="console"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "auto",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                textAlign: "left",
              }}
            >
              Transaction Log eg. Login Log, Monitoring Log, Control Log will
              show only last 3 months. If you want to see more data, you can
              download in AWS S3 in "bseviot-data" bucket
            </Typography>

            <div
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"User Data"} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={async () => {
                        let response = await fetch(
                          config.backend_url + "/be/admin/getuserdata",
                          {
                            method: "GET",
                            mode: "cors",
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + accessCookie("admintok"),
                            },
                          }
                        );
                        if (response.status === 200) {
                          let data = await response.json();
                          const element = document.createElement("a");
                          const file = new Blob([data], {
                            type: "text/stream",
                          });
                          element.href = URL.createObjectURL(file);
                          element.download = "UserData.csv";
                          document.body.appendChild(element);
                          element.click();
                        } else {
                        }
                      }}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <PhoneIphoneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Device Data"} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={async () => {
                        let response = await fetch(
                          config.backend_url + "/be/admin/getdevicedata",
                          {
                            method: "GET",
                            mode: "cors",
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + accessCookie("admintok"),
                            },
                          }
                        );
                        if (response.status === 200) {
                          let data = await response.json();
                          const element = document.createElement("a");
                          const file = new Blob([data], {
                            type: "text/stream",
                          });
                          element.href = URL.createObjectURL(file);
                          element.download = "DeviceData.csv";
                          document.body.appendChild(element);
                          element.click();
                        } else {
                        }
                      }}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <VpnKeyIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Login Log"} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={async () => {
                        let response = await fetch(
                          config.backend_url + "/be/admin/getloginlogs",
                          {
                            method: "GET",
                            mode: "cors",
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + accessCookie("admintok"),
                            },
                          }
                        );
                        if (response.status === 200) {
                          let data = await response.json();
                          const element = document.createElement("a");
                          const file = new Blob([data], {
                            type: "text/stream",
                          });
                          element.href = URL.createObjectURL(file);
                          element.download = "LoginLog.csv";
                          document.body.appendChild(element);
                          element.click();
                        } else {
                        }
                      }}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MonitorIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Monitoring Log"} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={async () => {
                        let response = await fetch(
                          config.backend_url + "/be/admin/getmonitorlogs",
                          {
                            method: "GET",
                            mode: "cors",
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + accessCookie("admintok"),
                            },
                          }
                        );
                        if (response.status === 200) {
                          let data = await response.json();
                          const element = document.createElement("a");
                          const file = new Blob([data], {
                            type: "text/stream",
                          });
                          element.href = URL.createObjectURL(file);
                          element.download = "MonitoringLog.csv";
                          document.body.appendChild(element);
                          element.click();
                        } else {
                        }
                      }}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <EngineeringIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={"Control Log"} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={async () => {
                        let response = await fetch(
                          config.backend_url + "/be/admin/getcontrollogs",
                          {
                            method: "GET",
                            mode: "cors",
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + accessCookie("admintok"),
                            },
                          }
                        );
                        if (response.status === 200) {
                          let data = await response.json();
                          const element = document.createElement("a");
                          const file = new Blob([data], {
                            type: "text/stream",
                          });
                          element.href = URL.createObjectURL(file);
                          element.download = "ControlLog.csv";
                          document.body.appendChild(element);
                          element.click();
                        } else {
                        }
                      }}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
