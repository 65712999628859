import React, { useState, useEffect } from "react";
import "./../App.css";
import { useHistory } from "react-router-dom";
import { Navbar } from "./minicomponents";
import "./Main.css";
import {
  Tabs,
  Tab,
  FormControl,
  ListItem,
  ListItemText,
  Button,
  Switch,
  Typography,
  Backdrop,
  MenuItem,
  TextField,
  Select,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchSharpIcon from "@material-ui/icons/SearchSharp";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement";
import QRCode from "qrcode";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DevicesOtherIcon from "@material-ui/icons/DevicesOther";
// import { RegisterdDevice } from "./../components";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Admin(props) {
  const history = useHistory();
  const [tabstate, setTabstate] = useState(0);
  const [click, setClick] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deviceOpen, setDeviceOpen] = React.useState(false);
  const classes = useStyles();
  const handleClick = () => setClick(!click);
  // const closeMobileMenu = () => setClick(false);
  // const [text, setText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  // const [datacard, setDatacard] = useState(false);

  const [state, setState] = React.useState({
    // USER MANAGER
    loading: Boolean,
    userExist: Boolean,
    searchType: "Username",
    searchWord: "",
    user: "",
    searchInput: false,
    controlAccess: "",
    serial: {},
    datacard: "",
    // DEVICE MANAGER
    machinetype: "",
    serialid: "",
    machineInput: Boolean,
    serialInput: Boolean,
    machineExist: Boolean,
    serialExist: Boolean,
    confirmAdd: false,
    // QR CODE
    qrInput: Boolean,
    qr: "",
    //serial token
    gettokenserial: "",
    generatedtoken: "",
    config_machinetype: [],
  });

  async function searchUser() {
    await setState({ ...state, loading: true });

    if (state.searchWord === "") {
      setState({ ...state, loading: false, searchInput: false });
    } else {
      let response = await fetch(config.backend_url + "/be/getuserinfo", {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + accessCookie("admintok"),
        },
        body: JSON.stringify({
          search_word: state.searchWord,
          search_type: state.searchType,
        }),
      });
      const data = await response.json();
      if (data.details === "User not found") {
        setState({
          ...state,
          searchInput: true,
          loading: false,
          userExist: false,
        });
      } else {
        await setState({
          ...state,
          user: data,
          loading: false,
          searchInput: true,
          userExist: true,
        });
      }
    }
  }

  async function handleDatacard(data) {
    let serialData = Object.values(JSON.parse(data.serial));
    for (var i = 0; i < Object.keys(JSON.parse(data.serial)).length; i++) {
      serialData[i]["testserial"] = Object.keys(JSON.parse(data.serial))[i];
    }
    await setState({
      ...state,
      datacard: data,
      controlAccess: data.controlaccess,
      serial: serialData,
    });
    setOpen(!open);
  }

  const handleSwitch = (data) => {
    let status = JSON.parse(state.controlAccess);
    if (!status.includes(data)) {
      status.push(data);
    } else {
      const index = status.indexOf(data);
      status.splice(index, 1);
    }

    status = JSON.stringify(status.sort());
    setState({
      ...state,
      controlAccess: status,
    });
  };

  async function changeControlAccess() {
    let response = await fetch(config.backend_url + "/be/useraccess", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        username: state.datacard.username,
        controlaccess: state.controlAccess,
      }),
    });
    let userData = state.user;
    for (var i = 0; i < userData.length; i++) {
      if (userData[i]["username"] === state.datacard.username) {
        userData[i]["controlaccess"] = state.controlAccess;
      }
    }
    setState({
      ...state,
      user: userData,
    });
    setOpen(false);
  }

  async function handleClose() {
    await setOpen(false);
    setState({
      ...state,
      controlAccess: state.datacard.controlaccess,
    });
  }

  const handleAdd = () => {
    if (state.machinetype === "") {
      if (state.serialid === "") {
        setState({
          ...state,
          machineInput: false,
          serialInput: false,
        });
      } else {
        setState({
          ...state,
          machineInput: false,
          serialInput: true,
        });
      }
    } else if (state.serialid === "") {
      if (state.machinetype === "") {
        setState({
          ...state,
          machineInput: false,
          serialInput: false,
        });
      } else {
        setState({
          ...state,
          machineInput: true,
          serialInput: false,
        });
      }
    } else {
      setState({
        ...state,
        machineInput: true,
        serialInput: true,
      });
      setDeviceOpen(!deviceOpen);
    }
  };

  async function handleDeviceChange() {
    let response = await fetch(config.backend_url + "/be/addmachine", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        machinetype: state.machinetype,
        serialid: state.serialid,
      }),
    });
    const data = await response.json();
    if (response.status !== 200) {
      if (data.detail === "Machine Type not found") {
        setState({
          ...state,
          machineExist: false,
          confirmAdd: true,
        });
      } else if (data.detail === "This serial ID has already been used") {
        setState({
          ...state,
          machineExist: true,
          serialExist: true,
          confirmAdd: true,
        });
      }
    } else {
      setState({
        ...state,
        machineExist: true,
        serialExist: false,
        confirmAdd: true,
      });
      // setDeviceOpen(!deviceOpen);
    }
  }

  const handleDeviceClose = () => {
    setDeviceOpen(false);
    setState({
      ...state,
      confirmAdd: false,
      machineExist: Boolean,
      serialExist: Boolean,
    });
  };

  const handleQR = () => {
    if (state.qr === "") {
      setState({
        ...state,
        qrInput: false,
      });
    } else {
      setState({
        ...state,
        qrInput: Boolean,
      });
      generateQrCode();
    }
  };

  const generateQrCode = async () => {
    try {
      const response = await QRCode.toDataURL(state.qr);
      setImageUrl(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/admin/me", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    if (response.status !== 200) {
      history.push("/");
      deleteCookie("admintok");
    } else {
    }
  }
  async function getdevicetoken() {
    let response = await fetch(config.backend_url + "/getdevicetoken", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessCookie("admintok"),
      },
      body: JSON.stringify({
        serialid: state.gettokenserial,
      }),
    });
    if (response.status === 200) {
      let data = await response.json();
      setState({
        ...state,
        generatedtoken: data.token,
      });
    } else {
    }
  }
  async function getMachineType() {
    let response = await fetch(
      config.backend_url + "/be/admin/get_machinetype",
      {
        method: "GET",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessCookie("admintok"),
        },
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      setState({
        ...state,
        config_machinetype: data,
      });
    } else {
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("admintok") !== "") {
      checkUserData(accessCookie("admintok"));
      getMachineType();
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <>
      <div className="container">
        <div className="admin-tab">
          <Navbar title={"User Manager"} />
        </div>

        <div className="admin-tab">
          <Tabs
            value={tabstate}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
            onChange={(e, v) => {
              setTabstate(v);
              if (tabstate === 0) {
                setState({
                  ...state,
                  loading: Boolean,
                  userExist: Boolean,
                  searchType: "Username",
                  searchWord: "",
                  user: "",
                  searchInput: false,
                  controlAccess: "",
                  serial: {},
                  datacard: "",
                });
              } else if (tabstate === 1) {
                setState({
                  ...state,
                  machinetype: "",
                  serialid: "",
                  machineInput: Boolean,
                  serialInput: Boolean,
                  machineExist: Boolean,
                  serialExist: Boolean,
                  confirmAdd: false,
                });
              } else if (tabstate === 2) {
                setState({
                  ...state,
                  gettokenserial: "",
                  generatedtoken: "",
                });
              } else if (tabstate === 3) {
                setState({
                  ...state,
                  urlInput: Boolean,
                  linkURL: "",
                });
                setImageUrl();
              }
            }}
            aria-label="disabled tabs example"
          >
            <Tab className="tab" label="User Manager" />
            <Tab className="tab" label="Device Manager" />
            <Tab className="tab" label="Device Token" />
            <Tab className="tab" label="QR code" />
          </Tabs>
        </div>
        <div className="manage-console">
          {tabstate === 0 && (
            <div
              className="console"
              style={{ height: "70vh", overflowY: "scroll" }}
            >
              <Backdrop className={classes.backdrop} open={open}>
                <div
                  className="data-card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "hidden",
                  }}
                >
                  {state.searchInput === false ? (
                    <div></div>
                  ) : (
                    <div>
                      {state.userExist === false ? (
                        <div></div>
                      ) : (
                        <div>
                          {state.datacard === "" ? (
                            <div></div>
                          ) : (
                            <div className="card-con">
                              <div className="card-text">
                                <Typography
                                  color="textPrimary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {JSON.stringify(
                                    state.datacard.username
                                  ).replace(/['"]+/g, "")}
                                </Typography>
                                <Typography
                                  color="textSecondary"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {JSON.stringify(
                                    state.datacard.userfirstname
                                  ).replace(/['"]+/g, "") +
                                    " " +
                                    JSON.stringify(
                                      state.datacard.userlastname
                                    ).replace(/['"]+/g, "")}{" "}
                                  |{" "}
                                  {JSON.stringify(
                                    state.datacard.useremail
                                  ).replace(/['"]+/g, "")}
                                </Typography>
                              </div>
                              <hr />
                              {state.serial === {} ? (
                                <div></div>
                              ) : (
                                state.serial.map((serialdata) => (
                                  <div className="user-manager-card">
                                    <div className="data-con">
                                      <b className="serial-name">
                                        {"Serial Name: " +
                                          serialdata.serialname}
                                      </b>
                                      <b className="machine-type">
                                        {"Machine type: " +
                                          serialdata.machinetype}
                                      </b>
                                    </div>
                                    {JSON.parse(state.controlAccess).includes(
                                      serialdata.testserial
                                    ) ? (
                                      <Switch
                                        checked={true}
                                        onChange={handleSwitch.bind(
                                          this,
                                          serialdata.testserial
                                        )}
                                        statecolor="primary"
                                      />
                                    ) : (
                                      <Switch
                                        style={{
                                          display: "flex",
                                          justifyContent: "right",
                                          alignItems: "right",
                                          float: "right",
                                        }}
                                        checked={false}
                                        onChange={handleSwitch.bind(
                                          this,
                                          serialdata.testserial
                                        )}
                                        statecolor="primary"
                                      />
                                    )}
                                  </div>
                                ))
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{
                        width: "50%",
                        height: "48px",
                        marginTop: "10px",
                        backgroundColor: "#3f51b5",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        margin: "5px",
                      }}
                      onClick={changeControlAccess}
                    >
                      OK
                    </Button>

                    <Button
                      style={{
                        width: "50%",
                        height: "48px",
                        marginTop: "10px",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px",
                      }}
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </Backdrop>

              <div>
                <div className="search-element">
                  <div className="mobile-search-type" onClick={handleClick}>
                    {/* <MenuIcon /> */}
                    <div className={click ? <div></div> : "mobile-menu"}>
                      <Select
                        name="searchType"
                        defaultValue="Username"
                        value={state.searchType}
                        onChange={handleChange}
                      >
                        {click ? setClick(false) : <div></div>}
                        <MenuItem value={"Username"}>Username</MenuItem>
                        <MenuItem value={"Email"}>Email</MenuItem>
                        <MenuItem value={"Firstname"}>Firstname</MenuItem>
                        <MenuItem value={"Lastname"}>Lastname</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <FormControl style={{ minWidth: 100, marginTop: 8 }}>
                    <Select
                      className="search-type"
                      name="searchType"
                      defaultValue="Username"
                      value={state.searchType}
                      onChange={handleChange}
                    >
                      <MenuItem value={"Username"}>Username</MenuItem>
                      <MenuItem value={"Email"}>Email</MenuItem>
                      <MenuItem value={"Firstname"}>Firstname</MenuItem>
                      <MenuItem value={"Lastname"}>Lastname</MenuItem>
                    </Select>
                  </FormControl>
                  <div className="search-bar">
                    <TextField
                      name="searchWord"
                      value={state.searchWord}
                      onChange={handleChange}
                      style={{ width: "100%", padding: "0px 5px" }}
                      placeholder="  Search..."
                      InputProps={{ disableUnderline: true }}
                    />
                  </div>
                  <div className="search-button">
                    <Button
                      startIcon={<SearchSharpIcon />}
                      onClick={searchUser}
                    >
                      Search
                    </Button>
                  </div>
                  <div className="mobile-search-button" onClick={searchUser}>
                    <SearchSharpIcon />
                  </div>
                </div>
              </div>

              <div>
                {state.loading === true ? (
                  <h2>
                    <AutorenewIcon />
                    Loading...
                  </h2>
                ) : (
                  <div>
                    {state.searchInput === false ? (
                      <div></div>
                    ) : (
                      <div>
                        {state.userExist === false ? (
                          <h2>User not found</h2>
                        ) : (
                          <div>
                            {state.user === "" ? (
                              <h2>Error, Please try again</h2>
                            ) : (
                              state.user.map((data) => (
                                <ListItem
                                  button
                                  onClick={handleDatacard.bind(this, data)}
                                >
                                  <ListItemText
                                    primary={
                                      "Username: " +
                                      JSON.stringify(data.username).replace(
                                        /['"]+/g,
                                        ""
                                      )
                                    }
                                    secondary={
                                      "Email: " +
                                      JSON.stringify(data.useremail).replace(
                                        /['"]+/g,
                                        ""
                                      )
                                    }
                                  />
                                </ListItem>
                              ))
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          {tabstate === 1 && (
            <div
              className="console"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // height: "40vh",
                alignItems: "center",
              }}
            >
              <Backdrop className={classes.backdrop} open={deviceOpen}>
                <div
                  className="data-card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    overflowX: "hidden",
                  }}
                >
                  <div className="card-con">
                    {state.machineExist === false ? (
                      <div>
                        <ErrorIcon
                          fontSize="large"
                          style={{ color: "black" }}
                        ></ErrorIcon>
                        <Typography
                          color="textPrimary"
                          variant="h6"
                          gutterBottom
                          style={{ fontWeight: "bold" }}
                        >
                          Machine Type not found.
                        </Typography>
                      </div>
                    ) : (
                      <div className="card-con">
                        {state.serialExist === true ? (
                          <div>
                            <ErrorIcon
                              fontSize="large"
                              style={{ color: "black" }}
                            ></ErrorIcon>
                            <Typography
                              color="textPrimary"
                              variant="h6"
                              gutterBottom
                              style={{ fontWeight: "bold" }}
                            >
                              This serial ID has already been used.
                            </Typography>
                          </div>
                        ) : (
                          <div className="card-con">
                            {state.machineExist === true &&
                            state.serialExist === false ? (
                              <div>
                                <CheckCircleIcon
                                  fontSize="large"
                                  style={{ color: "black" }}
                                ></CheckCircleIcon>
                                <Typography
                                  color="textPrimary"
                                  variant="h6"
                                  gutterBottom
                                  style={{ fontWeight: "bold" }}
                                >
                                  Machine added
                                </Typography>
                              </div>
                            ) : (
                              <div className="card-con">
                                <AssignmentIcon
                                  fontSize="large"
                                  style={{ color: "black" }}
                                ></AssignmentIcon>
                                <Typography
                                  color="textPrimary"
                                  variant="h6"
                                  gutterBottom
                                  style={{ fontWeight: "bold" }}
                                >
                                  Please verify the information before adding
                                  machine.
                                </Typography>
                                <div className="device-manager-card">
                                  <div className="data-con">
                                    <b className="machine-type">
                                      {"Machine type: " + state.machinetype}
                                    </b>
                                    <b className="serial-name">
                                      {"Serial ID: " + state.serialid}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {state.confirmAdd === false ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{
                            width: "50%",
                            height: "48px",
                            marginTop: "10px",
                            backgroundColor: "#3f51b5",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            margin: "5px",
                          }}
                          onClick={handleDeviceChange}
                        >
                          Confirm
                        </Button>
                        <Button
                          style={{
                            width: "50%",
                            height: "48px",
                            marginTop: "10px",
                            backgroundColor: "#e0e0e0",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "5px",
                          }}
                          onClick={handleDeviceClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          style={{
                            width: "100%",
                            height: "48px",
                            marginTop: "10px",
                            backgroundColor: "#e0e0e0",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "5px",
                          }}
                          onClick={handleDeviceClose}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </Backdrop>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Autocomplete
                  options={state.config_machinetype}
                  getOptionLabel={(option) => option}
                  // value={state.machinetype}
                  // onChange={handleChange}
                  onChange={(event, value) => {
                    setState({
                      ...state,
                      machinetype: value,
                    });
                  }}
                  style={{
                    // display: "flex",
                    alignItems: "right",
                    width: "100%",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!state.machineInput}
                      name="machinetype"
                      value={state.machinetype}
                      onChange={handleChange}
                      label="Machine Type"
                      helperText={
                        !state.machineInput && "Please specify machine"
                      }
                      style={{
                        // display: "flex",
                        alignItems: "right",
                        width: "100%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />
                  )}
                />

                <TextField
                  error={!state.serialInput}
                  name="serialid"
                  value={state.serialid}
                  onChange={handleChange}
                  label="Serial ID"
                  helperText={!state.serialInput && "Please specify serial ID"}
                  style={{
                    // display: "flex",
                    alignItems: "right",
                    width: "100%",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <div>
                  <Button
                    variant="contained"
                    color="default"
                    style={{
                      height: "48px",
                      marginTop: "10px",
                      marginRight: "10px",
                      backgroundColor: "#ffffff",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      // margin: "5px",
                    }}
                    startIcon={<DevicesOtherIcon />}
                    onClick={() =>
                      // window.open("/RegisterdDevice", "_blank").focus()
                      history.push("/RegisterdDevice")
                    }
                  >
                    Registered Device
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="default"
                    style={{
                      height: "48px",
                      marginTop: "10px",
                      backgroundColor: "#ffffff",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      // margin: "5px",
                    }}
                    startIcon={<AddCircleIcon />}
                    onClick={handleAdd}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          )}
          {tabstate === 2 && (
            <div
              className="console"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "auto",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  style={{ width: "80%" }}
                  label="Serial ID"
                  // onChange={(e) => setText(e.target.value)}
                  name="gettokenserial"
                  value={state.gettokenserial}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={getdevicetoken}>
                          <CheckCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {state.generatedtoken !== "" && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    style={{ width: "80%" }}
                    label="Token"
                    value={state.generatedtoken}
                    multiline
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton>
                    //         {/* <ContentCopyIcon /> */}
                    //         <CheckCircleIcon />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </div>
              )}
            </div>
          )}

          {tabstate === 3 && (
            <div
              className="console"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "auto",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  label="Serial ID"
                  // onChange={(e) => setText(e.target.value)}
                  style={{ width: "80%" }}
                  error={!state.qrInput}
                  helperText={!state.qrInput && "Please specify serial ID"}
                  name="qr"
                  value={state.qr}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleQR}>
                          <CheckCircleIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* <br />
              <div style={{ width: "100%" }}>
                <Button variant="contained" onClick={handleQR}>
                  Generate
                </Button>
              </div> */}
              {/* <br /> */}
              {imageUrl && (
                <div
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <a href={imageUrl} download={state.qr}>
                    <img src={imageUrl} alt="img" style={{ width: "30%" }} />
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
